@charset 'utf-8';

html {scroll-behavior: smooth;}

body {
  overflow-x: hidden;
  background-color: #{$background-color};
}

// Responsive Images
//==============================================================

@mixin object-fit($value) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: $value;
}

img {
  &.s-fit-contain {
    @include object-fit(contain);
  }
  &.m-fit-contain {
    @media #{$medium-and-up} {
      @include object-fit(contain);
    }
  }
  &.l-fit-contain {
    @media #{$large-and-up} {
      @include object-fit(contain);
    }
  }
  &.xl-fit-contain {
    @media #{$extra-large-and-up} {
      @include object-fit(contain);
    }
  }
  &.s-fit-cover {
    @include object-fit(cover);
  }
  &.m-fit-cover {
    @media #{$medium-and-up} {
      @include object-fit(cover);
    }
  }
  &.l-fit-cover {
    @media #{$large-and-up} {
      @include object-fit(cover);
    }
  }
  &.xl-fit-cover {
    @media #{$extra-large-and-up} {
      @include object-fit(cover);
    }
  }
  &.s-fit-scale-down {
    @include object-fit(scale-down);
  }
  &.m-fit-scale-down {
    @media #{$medium-and-up} {
      @include object-fit(scale-down);
    }
  }
  &.l-fit-scale-down {
    @media #{$large-and-up} {
      @include object-fit(scale-down);
    }
  }
  &.xl-fit-scale-down {
    @media #{$extra-large-and-up} {
      @include object-fit(scale-down);
    }
  }
  &.m-fit-none {
    @media #{$medium-and-up} {
      @include object-fit(none);
    }
  }
  &.l-fit-none {
    @media #{$large-and-up} {
      @include object-fit(none);
    }
  }
  &.xl-fit-none {
    @media #{$extra-large-and-up} {
      @include object-fit(none);
    }
  }
}

// Numéros de téléphone
// =============================================================

.tel {
  white-space: nowrap;
  @at-root :where(a#{&}) {
    text-decoration: none;
    color: inherit;
    @media #{$medium-and-up} {
      pointer-events: none;
    }
  }
}

// Utility Classes
// =============================================================

.no-select {
  user-select: none;
}
.section { // à optimiser
  padding: {
    top: 2rem;
    bottom: 2rem;
  }
}
.hide {
  display: none !important;
}
.no-mobile {
  display: none;
  @media #{$medium-and-up} {
    display: revert;
  }
}
.no-tablet {
  @media #{$medium-and-up} {
    display: none;
  }
  @media #{$large-and-up} {
    display: revert;
  }
}
.no-desktop {
  @media #{$large-and-up} {
    display: none !important;
  }
}
.mobile-only {
  @media #{$medium-and-up} {
    display: none !important;
  }
}
.tablet-only {
  display: none;
  @media #{$medium-and-up} {
    display: revert;
  }
  @media #{$large-and-up} {
    display: none !important;
  }
}
.desktop-only {
  display: none;
  @media #{$large-and-up} {
    display: revert;
  }
}
.index-page {
  .no-index {
      display: none;
  }
}
.reset {
  all: unset;
  display: revert;
  @at-root ul#{&},
  ol#{&},
  menu#{&} {
    list-style: none;
    > li::before { // Prevent VoiceOver from ignoring list semantics in Safari
      content: "\200B";
      float: left;
    }
  }
}
.no-wrap {
  white-space: nowrap;
}
.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-5,
.line-clamp-6,
.line-clamp-7,
.line-clamp-8,
.line-clamp-9 {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
$i: 1;
@while $i <= 9 {
  .line-clamp-#{$i} {
    height: unquote($line-height * $i + "em");
    -webkit-line-clamp: #{$i};
  }
  $i: $i + 1;
}
.center-block {
  display: block;
  margin: {
    right: auto;
    left: auto;
  }
}
.radius {
  border-radius: $border-radius;
}
.btn-radius {
  border-radius: $button-radius;
}
.height-100 {
  height: 100%;
}

// Box shadows
// =============================================================

.shadow-hover {transition: box-shadow ease-in-out .3s;}

.shadow-1 {
  box-shadow: $box-shadow-1;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-2;
  }
}
.shadow-2 {
  box-shadow: $box-shadow-2;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-3;
  }
}
.shadow-3 {
  box-shadow: $box-shadow-3;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-4;
  }
}
.shadow-4 {
  box-shadow: $box-shadow-4;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-5;
  }
}

.shadow-primary-1 {
  box-shadow: $box-shadow-primary-1;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-primary-2;
  }
}
.shadow-primary-2 {
  box-shadow: $box-shadow-primary-2;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-primary-3;
  }
}
.shadow-primary-3 {
  box-shadow: $box-shadow-primary-3;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-primary-4;
  }
}
.shadow-primary-4 {
  box-shadow: $box-shadow-primary-4;
  &.shadow-hover:hover,
  &.btn:hover {
    box-shadow: $box-shadow-primary-5;
  }
}


// Others
// =============================================================

a {
  svg, img[alt] {
    pointer-events: none;
  }
}

// Text Align
// =============================================================
.align-left {
  text-align: left;
}
.align-right {
  text-align: right
}
.align-center {
  text-align: center;
}

// Fil d'Ariane
// =============================================================
// #fil_ariane {
//   .first { mettre dans une feuille bug/hack/correctifs
//     display: inline;
//   }
// }