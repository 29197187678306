html {
    font-family: $primary-font-stack;
    font-size: 16px;
    font-weight: normal;
    color: $text-color;
    line-height: $line-height;
}

:where(h1, .title-1, h2, .title-2, h3, .title-3, h4, .title-4, h5, .title-5, h6, .title-6) {
    font-weight: bold;
    line-height: #{$headings-line-height};
}

h1,
.title-1 { 
    font-size: calc(#{$h1-font-size} - #{$h1-font-size} / 3);
    margin: calc(#{$h1-font-size} / 1.5) 0 calc(#{$h1-font-size} / 1) 0;
    span {
        display: block;
        font-size: calc(#{$h2-font-size} - #{$h2-font-size} / 4);
        line-height: inherit;
        margin: calc(#{$h2-font-size} / 1.5) 0 calc(#{$h2-font-size} / 1) 0;
    }
    &.h1-geoloc span {
        margin-top: .5rem;
        font-size: .6em;
        font-weight: lighter;  
        &::before {
            content: '\e0c8';
            display: inline-block;
            vertical-align: middle;
            margin-right: .5rem;
            font-family: #{$icon-font};
            color: #{$primary-color};
        }
    }
    @media #{$large-and-up} {
        font-size: #{$h1-font-size};
        span {
            font-size: #{$h2-font-size};
        }
        &.h1-geoloc span {
            font-size: .5em;
        }
    }
}
h2,
.title-2 {
    font-size: calc(#{$h2-font-size} - #{$h2-font-size} / 4);
    margin: calc(#{$h2-font-size} / 1.5) 0 calc(#{$h2-font-size} / 1) 0;
    @media #{$large-and-up} {
        font-size: #{$h2-font-size};
    }
}
h3,
.title-3 {
    font-size: calc(#{$h3-font-size} - #{$h3-font-size} / 4);
    margin: calc(#{$h3-font-size} / 1.5) 0 calc(#{$h3-font-size} / 1) 0;
    @media #{$large-and-up} {
        font-size: #{$h3-font-size};
    }
}
h4,
.title-4 {
    font-size: #{$h4-font-size};
    margin: calc(#{$h4-font-size} / 1.5) 0 calc(#{$h4-font-size} / 1) 0;
}
h5,
.title-5 {
    font-size: #{$h5-font-size};
    margin: calc(#{$h5-font-size} / 1.5) 0 calc(#{$h5-font-size} / 1) 0;
}
h6
.title-6 {
    font-size: #{$h6-font-size};
    margin: calc(#{$h6-font-size} / 1.5) 0 calc(#{$h6-font-size} / 1) 0;
}


:where(a) {
    color: $link-color;
    &:hover {
        text-decoration: none;
    }
}
nav:not(.fil-ariane) a,
.brand a,
a.lien-maps {
    color: inherit;
    text-decoration: none;
}

:where(em),
.italic {
    font-style: italic;
}
:where(strong),
.bold {
    font-weight: bolder;
}
:where(address) {
    font-style: normal;
}
.uppercase {
    text-transform: uppercase;
}
.lowercase {
    text-transform: lowercase;
}
.capitalize {
    text-transform: capitalize;
}
.x-small {
    font-size: .75em;
}
:where(small),
.small {
    font-size: .875em;
}
.large {
    font-size: 1.125em;
}
.x-large {
    font-size: 1.5em;
}
.font-secondary {
    font-family: $secondary-font-stack;
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp,
.material-icons-two-tone {
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
    vertical-align: middle;
    &.tiny {
      font-size: 1rem;
    }
    &.small {
      font-size: 1.25rem;
    }
    &.medium {
      font-size: 2rem;
    }
    &.large {
      font-size: 3rem;
    }
    &.primary,
    &.secondary,
    &.accent {
        padding: .5rem;
    }
    $i: 1;
    @while $i <= 9 {
        &.primary-#{$i}0 {
            padding: .5rem;
        }
        &.secondary-#{$i}0 {
            padding: .5rem;
        }
        &.accent-#{$i}0 {
            padding: .5rem;
        }
        $i: $i + 1;
    }
    &.height-100 {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}