.btn {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: {
        top: .5rem;
        right: 1rem;
        bottom: .5rem;
        left: 1rem;
    }
    font: {
        family: inherit;
        size: 1rem;
    }
    text: {
        decoration: none;
        align: center;
    }
    line-height: 1.5rem;
    color: inherit;
    border: none;
    border-radius: $button-radius; 
    background-color: transparent;
    overflow: hidden;
    vertical-align: middle;
    position: relative;
    transition: {
        property: background-color;
        duration: .3s;
        timing-function: ease-in-out;
    }
    &:active {
        background-color: $text-color-80;
        transition-duration: .15s;
    }
    @media #{$medium-and-up} {
        &:not(.fluid) {
            width: auto;
        }
    }
    @media #{$large-and-up} {
        &:hover,
        &:focus {
            background-color: $text-color-90;
        }
        &:active {
            background-color: $text-color-80;
        }
    }
    .material-icons,
    .material-icons-outlined,
    .material-icons-round,
    .material-icons-sharp,
    .material-icons-two-tone {
        margin: {
            top: -.125rem;
            right: .5rem;
            left: -.125rem;
        }
        font-size: 1.125rem;
        line-height: 0;
    }
    &.small {
        padding: {
            top: .25rem;
            right: .75rem;
            bottom: .25rem;
            left: .75rem;
        }
        .material-icons,
        .material-icons-outlined,
        .material-icons-round,
        .material-icons-sharp,
        .material-icons-two-tone {
            margin-left: 0;
            font-size: 1rem;
        }
    }
    &.large {
        padding: {
            top: .75rem;
            right: 1.5rem;
            bottom: .75rem;
            left: 1.5rem;
        }
        .material-icons,
        .material-icons-outlined,
        .material-icons-round,
        .material-icons-sharp,
        .material-icons-two-tone {
            font-size: 1.25rem;
        }
    }
    &[disabled],
    &.disabled {
        opacity: .4;
        pointer-events: none;
    }
    &.primary,
    &.secondary,
    &.accent {
        color: white;
        transition: {
            property: background-color;
            duration: .3s;
            timing-function: ease-in-out;
        }
        &:active {
            transition-duration: .15s;
        }
        &::before {
            opacity: 0;
        }
    }
    &.primary {
        background-color: $primary-color;
        &:active {
            background-color: $primary-color-darken;
        }
        @media #{$large-and-up} {
            &:hover,
            &:focus {
                background-color: $primary-color-lighten;
            }
            &:active {
                background-color: $primary-color-darken;
            }
        }
    }
    &.secondary {
        background-color: $secondary-color;
        &:active {
            background-color: $secondary-color-darken;
        }
        @media #{$large-and-up} {
            &:hover,
            &:focus {
                background-color: $secondary-color-lighten;
            }
            &:active {
                background-color: $secondary-color-darken;
            } 
        }
    }
    &.accent {
        background-color: $accent-color;
        &:active {
            background-color: $accent-color-darken;
        }
        @media #{$large-and-up} {
            &:hover,
            &:focus {
                background-color: $accent-color-lighten;
            }
            &:active {
                background-color: $accent-color-darken;
            }
        }
    }
    &.outlined {
        padding: {
            top: calc(.5rem - 1px);
            right: calc(1rem - 1px);
            bottom: calc(.5rem - 1px);
            left: calc(1rem - 1px);
        }
        color: $text-color;
        border: {
            style: solid;
            width: 1px;
            color: $text-color-80;
            radius: $button-radius;
        }
        background-color: transparent;
        transition: {
            property: background-color;
            duration: .3s;
            timing-function: ease-in-out;
        }
        &:active {
            background-color: $text-color-90;
        }
        &.small {
            padding: {
                top: calc(.25rem - 1px);
                right: calc(.75rem - 1px);
                bottom: calc(.25rem - 1px);
                left: calc(.75rem - 1px);
            }
        }
        &.large {
            padding: {
                top: calc(.75rem - 1px);
                right: calc(1.5rem - 1px);
                bottom: calc(.75rem - 1px);
                left: calc(1.5rem - 1px);
            }
        }
        &.primary,
        &.secondary,
        &.accent {
            background-color: transparent;
        }
        &.primary {
            &:active {
                background-color: $primary-color-90;
            }
            .material-icons,
            .material-icons-outlined,
            .material-icons-round,
            .material-icons-sharp,
            .material-icons-two-tone {
                color: $primary-color;
            }
        }
        &.secondary {
            &:active {
                background-color: $secondary-color-90;
            }
            .material-icons,
            .material-icons-outlined,
            .material-icons-round,
            .material-icons-sharp,
            .material-icons-two-tone {
                color: $secondary-color;
            }
        }
        &.accent {
            &:active {
                background-color: $accent-color-90;
            }
            .material-icons,
            .material-icons-outlined,
            .material-icons-round,
            .material-icons-sharp,
            .material-icons-two-tone {
                color: $accent-color;
            }
        }
        @media #{$large-and-up} {
            transition: {
                property: background-color, border-color;
                duration: .3s;
                timing-function: ease-in-out;
            }
            &:hover,
            &:focus {
                border-color: $text-color;
            }
            &.primary {
                &:hover,
                &:focus {
                    border-color: $primary-color;
                }
            }
            &.secondary {
                &:hover,
                &:focus {
                    border-color: $secondary-color;
                }
            }
            &.accent {
                &:hover,
                &:focus {
                    border-color: $accent-color;
                }
            }
        }
    }
}

button,
input[type=button], 
input[type=submit] {
    cursor: pointer;
}